
import { Component, Vue } from "vue-property-decorator";
import ViewMixin from "@/mixins/Crud/ViewMixin";
import DefaultLayout from "@/layout/DefaultLayout.vue";
import { mixins } from "vue-class-component";
import { SoftwareStoreMixin } from "@/mixins/Stores/BaseData/SoftwareStoreMixin";

@Component({
  components: { DefaultLayout },
  mixins: [ViewMixin],
})
export default class SoftwareView extends mixins(
  ViewMixin,
  SoftwareStoreMixin
) {
  //private name = "SoftwareView";

  protected resource = "/software";
  protected descriptionField = "software";
  protected redirectRouteNameOnDeleteSuccess = "BaseDataSoftwareList";

  public removeAction(options: any): any {
    console.debug("remove Action (software view)", options);
    this.delSoftwareItemAction(options);
  }

  protected onAdd(): void {
    this.$router.push({ name: "BaseDataSoftwareCreate" });
  }

  protected onRemoveSuccess(response: any, resourceName: string): void {
    console.debug("on remove Success (SoftwraeView)", response, resourceName);
  }
}
