import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { Software } from "@/config/Modules";

const SoftwareModule = namespace(Software.store);

@Component({})
export class SoftwareStoreMixin extends Vue {
  /**
   * Actions
   */
  @SoftwareModule.Action("create")
  protected createSoftwareItemAction: any;

  @SoftwareModule.Action("findOne")
  protected findOneSoftwareItemAction: any;

  @SoftwareModule.Action("update")
  protected updateSoftwareItemAction: any;

  @SoftwareModule.Action("findAll")
  protected findAllSoftwareItemsAction: any;

  @SoftwareModule.Action("resetSave")
  protected resetSaveSoftwareAction: any;

  @SoftwareModule.Action("resetList")
  protected resetListSoftwareAction: any;

  @SoftwareModule.Action("del")
  protected delSoftwareItemAction: any;

  @SoftwareModule.Action("findAllSubresource")
  protected findAllSubresourceSoftwareItemsAction: any;

  @SoftwareModule.Action("setDataList")
  protected setDataListSoftwareAction: any;

  @SoftwareModule.Action("upload")
  protected uploadSoftwareAction: any;

  /**
   * Getters
   */
  @SoftwareModule.Getter("getError")
  protected getSoftwareError: any;

  @SoftwareModule.Getter("getIsLoading")
  protected getSoftwareIsLoading: any;

  @SoftwareModule.Getter("getIsSaving")
  protected getSoftwareIsSaving: any;

  @SoftwareModule.Getter("getDataItem")
  protected getSoftwareItem: any;

  @SoftwareModule.Getter("getSuccess")
  protected getSoftwareSuccess: any;

  @SoftwareModule.Getter("getDataList")
  protected getSoftwareList: any;

  @SoftwareModule.Getter("getTotal")
  protected getSoftwareTotal: any;

  @SoftwareModule.Getter("getDeletedSuccess")
  protected getSoftwareDeletedSuccess: any;

  @SoftwareModule.Getter("getValidationErrors")
  protected getSoftwareValidationErrors: any;

  /**
   * Mutations
   */
  @SoftwareModule.Mutation("IS_LOADING")
  protected setSoftwareLoading: any;

  @SoftwareModule.Mutation("SET_IS_SAVING")
  protected setSoftwareIsSaving: any;

  @SoftwareModule.Mutation("SET_ERROR")
  protected setSoftwareError: any;

  @SoftwareModule.Mutation("SET_SUCCESS")
  protected setSoftwareSuccess: any;

  @SoftwareModule.Mutation("SET_DATA_ITEM")
  protected setSoftwareItem: any;

  @SoftwareModule.Mutation("SET_DATA_LIST")
  protected setSoftwareList: any;

  @SoftwareModule.Mutation("SET_TOTAL")
  protected setSoftwareTotal: any;

  @SoftwareModule.Mutation("SET_DELETED_SUCCESS")
  protected setSoftwareDeletedSuccess: any;

  @SoftwareModule.Mutation("SET_VALIDATION_ERRORS")
  protected setSoftwareValidationErrors: any;

  // Additional...
}
